import { PublicKey } from "@solana/web3.js";
import {Chain} from "../config/Chain";
import {utils} from "ethers";

export function validateAddress(address, chainId, tokenType) {
    if (chainId === Chain.Solana) {
        try {
            new PublicKey(address)
            return true
        } catch (e) { return false }
    }
    return utils.isAddress(address)
    
}