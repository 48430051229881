import {cn} from "../../utils/utils";

function Skeleton({
                      className,
                      ...props
                  }) {
    return (
        <div
            className={cn("animate-pulse rounded bg-tertiary", className)}
            {...props}
        />
    )
}

export { Skeleton }