import React, { useEffect, useState } from "react";
import { cn } from "../../../utils/utils";

const Table = React.forwardRef(({ className, ...props }, ref) => (
    <div className="relative w-full border-collapse border-spacing-0 table-fixed">
        <table
            ref={ref}
            className={cn("w-full caption-bottom text-xs", className)}
            {...props}
        />
    </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
    <thead ref={ref} className={cn("[&_tr]:border-b sticky top-0 bg-background z-10", className)} {...props} />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
    <tbody
        ref={ref}
        className={cn("[&_tr:last-child]:border-0", className)}
        {...props}
    />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
    <tfoot
        ref={ref}
        className={cn("bg-primary font-medium text-primary-foreground", className)}
        {...props}
    />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef(({ className, flash: initialFlash, ...props }, ref) => {
    const [flash, setFlash] = useState(initialFlash);

    useEffect(() => {
        if (!initialFlash) return;
        const timeout = setTimeout(() => {
            setFlash(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [initialFlash]);

    return (
        <tr
            ref={ref}
            className={cn(
                "border-b transition-colors data-[state=selected]:bg-muted",
                className,
                {
                    "bg-brand": flash
                }
            )}
            {...props}
        />
    );
});
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
    <th
        ref={ref}
        className={cn(
            "px-4 h-[36px] text-left text-xs align-middle font-medium text-tertiary-foreground [&:has([role=checkbox])]:pr-0",
            className
        )}
        {...props}
    />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef(({ className, ...props }, ref) => {
    return (
        <td
            ref={ref}
            className={cn("px-4 py-3 text-xs text-foreground align-middle [&:has([role=checkbox])]:pr-0", className)}
            {...props}
        />
    );
});
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
    <caption
        ref={ref}
        className={cn("mt-4 text-sm text-secondary-foreground", className)}
        {...props}
    />
));
TableCaption.displayName = "TableCaption";

export {
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TableCell,
    TableCaption,
};
