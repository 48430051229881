import {useCallback, useState} from "react";

export function HandleInputs({
     initialState,
}) {
    const [inputs, setInputs] = useState(initialState);
    const [errors, setErrors] = useState({});

    const updateInputs = useCallback((name, value) => {
        setInputs(prev => ({...prev, [name]: value}));
    }, []);

    const clearInputs = useCallback(() => {
        setInputs(initialState);
    }, [initialState]);

    const clearErrors = useCallback(() => {
        setErrors({});
    }, []);

    const validateInputs = useCallback(() => {
        return inputs;
    }, [inputs])

    const result = {
        inputs,
        setInputs,
        updateInputs,
        clearInputs,
        validateInputs,
    };

    return {
        ...result,
        errors,
        setErrors,
        clearErrors
    };
}
