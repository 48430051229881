import * as React from "react"
import * as SelectPrimitive from "@radix-ui/react-select"
import {Check, ChevronDown, HelpCircle, Loader2} from "lucide-react"
import {useEffect, useRef, useState} from "react";
import {cn} from "../../utils/utils";

const SelectRoot = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectTrigger = React.forwardRef(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            "w-full flex items-center rounded bg-background px-3 h-[34px] placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:bg-muted disabled:text-muted-foreground transition-all [&[data-state=open]>#chevron]:rotate-180 hover:bg-primary-hover",
            className
        )}
        {...props}
    >
        {children}
        <SelectPrimitive.Icon asChild>
            <ChevronDown id="chevron" className="h-4 w-4 shrink-0 transition-transform duration-200 text-quaternary-foreground" />
        </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectContent = React.forwardRef(({ className, children, position = "popper", ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                "font-berkeleyMono relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                position === "popper" &&
                "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                className
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.Viewport
                className={cn(
                    // "p-1",
                    position === "popper" &&
                    "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn("py-1.5 px-3 text-sm", className)}
        {...props}
    />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef(({ className, children, selected, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 px-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[state=checked]:bg-active hover:bg-primary-hover",
            className
        )}
        {...props}
    >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
        <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
            <SelectPrimitive.ItemIndicator>
                <Check className="h-4 w-4 text-brand-foreground" />
            </SelectPrimitive.ItemIndicator>
        </span>
    </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const MultiSelectItem = React.forwardRef(({ className, children, selected, ...props }, ref) => (
    <button
        ref={ref}
        className={cn(
            "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 px-2 text-sm outline-none hover:bg-accent disabled:pointer-events-none disabled:opacity-50 hover:bg-primary-hover data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            selected && "bg-active",
            className
        )}
        {...props}
    >
        <div className="text-left">{children}</div>
        <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
            {selected && <Check className="h-4 w-4 text-brand-foreground" />}
        </span>
    </button>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={cn("-mx-1 my-1 h-px bg-muted", className)}
        {...props}
    />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

const Select = React.forwardRef(
    (props, ref) => {

        const {
            id,
            value,
            options,
            label,
            onChange,
            placeholder,
            error,
            prefixLabel,
            prefixIcon,
            info,
            checkbox,
            className,
            triggerClassName,
            hideSuffix,
            loading,
            ...rest
        } = props;

        const [open, setOpen] = useState(false)

        const onValueChange = (newValue) => {
            if (onChange) {
                onChange(newValue)
            }
        }

        const selectedOption = options.find((option) => option.value === value)

        return (
            <div>
                <div className={cn(
                    "flex items-center h-9 w-full rounded border bg-background text-sm relative",
                    open && "border-brand-border ring-4 ring-ring",
                    error && "border-destructive-border",
                    props.disabled && "bg-muted opacity-50",
                    className
                )}>
                    {prefixLabel && (
                        <div className="border-r h-full flex items-center px-3">
                            <p className="text-tertiary-foreground">{prefixLabel}</p>
                        </div>
                    )}
                    <SelectRoot
                        onOpenChange={() => {
                            setOpen(!open)
                            // if (onOpen) onOpen()
                        }}
                        value={value}
                        onValueChange={(newValue) => onValueChange(newValue)}
                        {...rest}
                    >
                        <SelectTrigger
                            className={cn("w-full", triggerClassName)}
                            disabled={loading}
                        >
                            {prefixIcon && <props.prefixIcon className="w-5 h-5 shrink-0 text-secondary-foreground mr-2" />}
                            {selectedOption?.icon && <selectedOption.icon className="w-5 h-5 shrink-0 text-secondary-foreground mr-2" />}
                            {selectedOption?.label ? (
                                <p
                                    className={cn("truncate flex-1 text-left mr-2", {
                                        "text-muted-foreground": !selectedOption?.label,
                                    })}
                                >
                                    {selectedOption?.label}
                                </p>
                            ): (
                                <p className="truncate flex-1 text-left text-muted-foreground">{placeholder}</p>
                            )}
                            {(selectedOption?.suffix && !hideSuffix) && <p className="mr-2 text-secondary-foreground">{selectedOption?.suffix}</p>}
                        </SelectTrigger>
                        <SelectContent>
                            <div className="p-1">
                                {options.length > 0 ? options.map((option, index) => (
                                    <SelectItem
                                        key={index}
                                        value={option.value}
                                        disabled={option.disabled ?? false}
                                    >
                                        <div>
                                            <div className="flex items-center">
                                                {option.icon && <option.icon className="w-5 h-5 shrink-0 text-secondary-foreground mr-2" />}
                                                <p className="truncate mr-5">{option.label}</p>
                                            </div>
                                            {option.suffix && <p className="text-secondary-foreground">{option.suffix}</p>}
                                        </div>
                                    </SelectItem>
                                )) : <p className="text-sm py-1.5 px-2">No results.</p>}
                            </div>
                        </SelectContent>
                    </SelectRoot>
                </div>
            </div>
        );
    }
);
Select.displayName = "Select"

export {
    Select
}