import {HandleInputs, handleInputs} from "../utils/handleInputs";
import {Button} from "./ui/button";
import * as React from "react";
import {
    Dialog, DialogClose,
    DialogContent,
    DialogFields, DialogFooter,
    DialogHeader,
    DialogHeaderIcon,
    DialogTitle,
    DialogTrigger
} from "./ui/dialog";
import {ArrowRightFromLine} from "lucide-react";
import {FormInput} from "./ui/form/input";
import {chainExplorerBaseUrl} from "../config/Chain";

const DEFAULT_STATE = {
    open: false,
    destination: '',
    amount: '',

    transferLoading: false,
}

export function ModalTransfer(props) {

    const {
        chainId,
        wallet,
        submitTransaction
    } = props;

    const {
        inputs,
        setInputs,
        updateInputs,
        errors,
        setErrors,
        clearErrors
    } = HandleInputs({
        initialState: DEFAULT_STATE,
    })

    const validateInputs = () => {
        let newErrors = {};

        if (!inputs.destination) {
            newErrors.destination = 'Destination is required'
        }
        if (!inputs.amount) {
            newErrors.amount = 'Amount is required'
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return false;
        }
        return true;
    }

    const onSubmit = () => {
        if (validateInputs()) {
            submitTransaction(wallet, inputs)
        }
    }

    return (
        <Dialog
            open={inputs.open}
            onOpenChange={open => setInputs((prev) => ({...prev, open}))}
        >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setInputs((prev) => ({...prev, open: true}))}
                    style={{
                        marginRight: 10
                    }}
                >
                    Transfer
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogHeaderIcon icon={ArrowRightFromLine} />
                    <DialogTitle>Transfer</DialogTitle>
                </DialogHeader>
                <DialogFields>
                    <FormInput
                        inputKey="destination"
                        inputs={inputs}
                        errors={errors}
                        onUpdate={updateInputs}
                        errors={errors}
                        clearErrors={clearErrors}
                        label="Destination"
                        placeholder="Enter address"
                    />
                    <FormInput
                        inputKey="amount"
                        inputs={inputs}
                        errors={errors}
                        onUpdate={updateInputs}
                        errors={errors}
                        clearErrors={clearErrors}
                        type="number"
                        label="Amount"
                        placeholder="Enter amount"
                    />
                    <p className="text-sm">View balance <a className="text-brand-foreground hover:underline" href={`${chainExplorerBaseUrl[chainId]}address/${wallet.addresses[0].address}`} target="_blank">here</a></p>
                </DialogFields>
                <DialogFooter className="sm:justify-start">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" disabled={inputs.transferLoading}>Close</Button>
                    </DialogClose>
                    <Button type="button" onClick={() => onSubmit()}>
                        Transfer
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}