import {Label} from "../label";

export function FormLabel(props) {
    const {
        id,
        label,
    } = props
    return (
        <div className="w-full flex justify-between h-4 mb-1.5">
            <div className="flex items-center space-x-2">
                <Label htmlFor={id}>{label}</Label>
            </div>
        </div>
    )
}