import * as React from "react";
import { useState } from "react";
import { cva } from "class-variance-authority";
import {cn} from "../../utils/utils";

const inputWrapperVariants = cva("flex items-center rounded border bg-background text-sm transition-colors", {
    variants: {
        size: {
            default: "w-full h-9",
            sm: "h-6"
        }
    },
    defaultVariants: {
        size: "default"
    }
});

const inputVariants = cva("w-full rounded bg-transparent placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:text-muted-foreground", {
    variants: {
        size: {
            default: "px-3 h-[34px]",
            sm: "px-2 h-[34px]"
        }
    },
    defaultVariants: {
        size: "default"
    }
});

const Input = React.forwardRef((props, ref) => {
    const {
        id,
        variantSize = "default",
        error,
        prefixLabel,
        prefixIcon,
        tooltip,
        tooltipDescription,
        tooltipDescriptionComponent,
        tooltipDisableHoverableContent,
        suffixButton,
        loading,
        numberRange,
        className,
        type,
        dontTrimLeadingZero,
        ...rest
    } = props;
    const [focused, setFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    let inputType = type;
    if (type === 'password' && isPasswordVisible) {
        inputType = 'text';
    }

    const handleInputChange = (event) => {

        if (numberRange) {
            const value = event.target.value;
            const validPattern = /^(\d+(-\d*)?)?$/; // Allows numbers, a single hyphen, and number ranges

            // Check if the input value matches the valid pattern
            if (!validPattern.test(value)) {
                event.preventDefault();
                return;
            }
        } else {
            // If the input type is number and the value is negative, prevent the change
            if (type === 'number' && event.target.value < 0) {
                event.preventDefault();
                return;
            }
        }

        if (!dontTrimLeadingZero) {
            // If the input starts with a zero and is followed by another number, trim the leading zero
            if (/^0(\d+)/.test(event.target.value)) {
                event.target.value = event.target.value.replace(/^0(\d+)/, '$1');
            }

            // If the input starts with multiple zeros, replace them with a single zero
            if (/^0{2,}/.test(event.target.value)) {
                event.target.value = event.target.value.replace(/^0{2,}/, '0');
            }
        }

        // If the input is valid, call the original onChange prop if it exists
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div className="flex items-center">
            <div className={cn(
                // "z-10",
                inputWrapperVariants({ size: variantSize }),
                focused && !error && "border-brand-border ring-4 ring-ring",
                error && "border-destructive-border",
                props.disabled && "bg-muted/50",
                suffixButton && "rounded-r-none ring-0",
                className
            )}>
                <input
                    type={inputType}
                    className={cn(
                        inputVariants({ size: variantSize }),
                        prefixIcon && "pl-2",
                        tooltip && "pr-2",
                    )}
                    ref={ref}
                    {...rest}
                    onChange={handleInputChange}
                    onFocus={(event) => {
                        setFocused(true)
                        if (props.onFocus) props.onFocus(event)
                    }}
                    onBlur={(event) => {
                        setFocused(false)
                        if (props.onBlur) props.onBlur(event)
                    }}
                    onWheel={(e) => e.target.blur()}
                />
            </div>
            {suffixButton && suffixButton}
        </div>
    );
});
Input.displayName = "Input";

export {
    Input
};
