export const Chain = {
    Mainnet: 1,
    Goerli: 5,
    Optimism: 10,
    BSC: 56,
    Shibarium: 109,
    Polygon: 137,
    Base: 8453,
    Arbitrum: 42161,
    Avax: 43114,
    Solana: 1399811149
}

export const chainExplorerBaseUrl = {
    [Chain.Mainnet]: "https://etherscan.io/",
    [Chain.Goerli]: "https://goerli.etherscan.io/",
    [Chain.BSC]: "https://bscscan.com/",
    [Chain.Shibarium]: "https://www.shibariumscan.io/",
    [Chain.Base]: "https://basescan.org/",
    [Chain.Arbitrum]: "https://arbiscan.io/",
    [Chain.Avax]: "https://snowtrace.io/",
    [Chain.Solana]: "https://solscan.io/",
};

export function getChainOptions() {
    return [
        { label: "Ethereum", value: Chain.Mainnet },
        { label: "Arbitrum", value: Chain.Arbitrum },
        { label: "Base", value: Chain.Base },
        { label: "Avalanche", value: Chain.Avax },
        { label: "Polygon", value: Chain.Polygon },
        { label: "Optimism", value: Chain.Optimism },
        // { label: "Solana", value: Chain.Solana },
    ]
}