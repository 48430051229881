import {
    flexRender,
    getCoreRowModel, getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "./table"
import {useEffect, useMemo, useRef, useState} from "react";
import {Skeleton} from "../skeleton";
import {cn} from "../../../utils/utils";

export function DataTable({
     columns,
     data,
     loading,
     error,
     className,
     skeletonClassName,
     onSelectedRowsUpdate,
     selectable,
}) {

    const [rowSelection, setRowSelection] = useState({})
    const [sorting, setSorting] = useState([])
    const tableData = useMemo(
        () => loading ? Array(6).fill({}) : data,
        [loading, data]
    );
    const tableColumns = useMemo(
        () =>
            loading
                ? columns.map((column) => ({
                    ...column,
                    cell: <Skeleton className={cn("w-full h-[24px]", skeletonClassName)} />,
                }))
                : columns,
        [loading, columns]
    );
    const table = useReactTable({
        data: tableData,
        columns: tableColumns,
        state: {
            rowSelection,
            sorting,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // debugTable: true,
    })

    const tableContainerRef = useRef<HTMLDivElement>(null)
    const { rows } = table.getRowModel()

    useEffect(() => {
        if (!onSelectedRowsUpdate) return;
        const selectedRows = rows.filter((row, index) => rowSelection[index])
        onSelectedRowsUpdate(selectedRows)
    }, [rowSelection, data]);

    return (
        <div className={cn("overflow-auto", className)}>
            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const meta = header.column.columnDef.meta
                                return (
                                    <TableHead key={header.id} className={meta?.headerClassName}>
                                        <div className={cn("flex", {
                                            "justify-start": meta?.align === "left",
                                            "justify-center": meta?.align === "center",
                                            "justify-end": meta?.align === "right",
                                        })}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </div>
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {rows?.length ? (
                        rows.map((virtualRow) => {
                            const row = rows[virtualRow.index]
                            const rowSelected = row.getIsSelected()
                            return (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                    className={cn(
                                        "hover:bg-secondary text-tertiary-foreground",
                                    )}
                                    onClick={() => {
                                        if (!selectable) return
                                        row.toggleSelected(!rowSelected)
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        const meta = cell.column.columnDef.meta
                                        return (
                                            <TableCell
                                                key={cell.id}
                                                className={cn("", meta?.cellClassName)}
                                            >
                                                <div className={cn("flex", {
                                                    "justify-start": meta?.align === "left",
                                                    "justify-center": meta?.align === "center",
                                                    "justify-end": meta?.align === "right",
                                                })}>
                                                    <div className={cn("flex whitespace-nowrap transition-colors duration-300", {
                                                        // "w-full": loading,
                                                        // "w-fit": !loading
                                                    })}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </div>
                                                </div>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                <>
                                    {error ? (
                                        <p className="text-sm text-destructive-foreground">{error}</p>
                                    ) : (
                                        <p className="text-sm">No results.</p>
                                    )}
                                </>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}