import * as React from "react";
import {cn} from "../../../utils/utils";
import {FormLabel} from "./label";
import {Input} from "../input";

export function FormInput(props) {
    const {
        inputKey,
        inputs,
        onUpdate,
        description,
        errors,
        clearErrors,
        className,
        inputClassName,
        hideInput = false,
        hideErrorText = false,
        label,
        labelTooltip,
        labelTooltipDescription,
        labelTooltipDescriptionComponent,
        checkbox,
        ...rest
    } = props
    const id = `formInput_${inputKey.toLowerCase()}`
    const error = errors ? errors[inputKey] : undefined
    return (
        <div className={cn("text-left", className)}>
            {label && (
                <FormLabel
                    inputs={inputs}
                    onUpdate={onUpdate}
                    clearErrors={clearErrors}
                    id={id}
                    label={label}
                    labelTooltip={labelTooltip}
                    labelTooltipDescription={labelTooltipDescription}
                    labelTooltipDescriptionComponent={labelTooltipDescriptionComponent}
                    checkbox={checkbox}
                />
            )}
            <Input
                id={id}
                value={!hideInput ? inputs[inputKey] ?? '' : ''}
                onChange={(e) => {
                    onUpdate(inputKey, e.target.value)
                }}
                error={error}
                onFocus={(event) => {
                    if (props.onFocus) props.onFocus(event)
                    if (clearErrors) clearErrors()
                }}
                className={inputClassName}
                {...rest}
            />
            {error && !hideErrorText && <p className="text-sm text-destructive-foreground mt-1.5">{error}</p>}
            {description && <p className="text-sm text-tertiary-foreground mt-1.5">{description}</p>}
        </div>
    )
}